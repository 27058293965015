import { template as template_2896868514754bea808f97fbd8d7970d } from "@ember/template-compiler";
const WelcomeHeader = template_2896868514754bea808f97fbd8d7970d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
