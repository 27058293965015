import { template as template_8877f8340e894005b4e69e8d025b0960 } from "@ember/template-compiler";
const FKLabel = template_8877f8340e894005b4e69e8d025b0960(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
