import { template as template_1e5cb7938e93417486013609f5225569 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
const FKCol = template_1e5cb7938e93417486013609f5225569(`
  <div class={{concatClass "form-kit__col" (if @size (concat "--col-" @size))}}>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKCol;
